import * as React from 'react'
import loadable from '@loadable/component'

const FeatureRadio = loadable(() => import('/src/components/Form/FeatureRadio'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))

const StepProtect = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet
            legend={'What would you like to cover?'}
            groupName={'protect'}
        >
            <FieldSpacer>
                <FeatureRadio
                    label={'My Family'}
                    groupName={'protect'}
                    name={'protectFamily'}
                    value={'family'}
                    icon={'family'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer>
                <FeatureRadio
                    label={'My Mortgage'}
                    groupName={'protect'}
                    name={'protectMortgage'}
                    value={'mortgage'}
                    icon={'home'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer>
                <FeatureRadio
                    label={"I'm Not Sure"}
                    groupName={'protect'}
                    name={'protectUnsure'}
                    value={'unsure'}
                    icon={'unsure'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepProtect
